/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Hairline.woff");
	font-weight: 100;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Thin.woff");
	font-weight: 200;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-UltraLight.woff");
	font-weight: 300;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Light.woff");
	font-weight: 400;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Regular.woff");
	font-weight: 500;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Medium.woff");
	font-weight: 600;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-DemiBold.woff");
	font-weight: 700;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Bold.woff");
	font-weight: 800;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-ExtraBold.woff");
	font-weight: 900;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff/DanaFaNum-Black.woff");
	font-weight: 200;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-Hairline.woff2");
	font-weight: 100;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-Thin.woff2");
	font-weight: 200;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-UltraLight.woff2");
	font-weight: 300;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-Light.woff2");
	font-weight: 400;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-Regular.woff2");
	font-weight: 500;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-Medium.woff2");
	font-weight: 600;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-DemiBold.woff2");
	font-weight: 700;
}

@font-face {
	font-family: "dana";
	src: url("../dana/woff2/DanaFaNum-Bold.woff2");
	font-weight: 800;
}

body {
	font-family: Montserrat, "dana";
}

.rdw-editor-main {
	padding: 0.5rem 1.2rem;
	border: 1px solid #f1f1f1;
	border-bottom-left-radius: 0.357rem;
	border-bottom-right-radius: 0.357rem;
	border-top: none;
}

.rdw-editor-toolbar {
	font-family: "Montserrat";
	margin-bottom: 0 !important;
}
